import React from 'react'
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import 'less/admin/adminsettings.less';

@withRouter
export default class AdminSettings extends React.Component {
    static propTypes = ReactRouterPropTypes;
    
    render() {
        const {history} = this.props;

        return (<div className="admin-settings">
            <div>
                <h1>Apollo Administration</h1>
                <div className="settings-section">
                    <i className="fa fa-users" />
                    <div className="section-name" onClick={() => history.push('/admin/company')}>Company Management</div>
                    <div className="section-description">Modify company, group and user settings</div>
                </div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" aria-hidden="true" />
                    <div className="section-name" onClick={() => history.push('/admin/formulary')}>Formulary</div>
                    <div className="section-description">Add, edit and delete formulary lists</div>
                </div>
                <div className="settings-section">
                    <i className="fa fa-users" />
                    <div className="section-name" onClick={() => history.push('/admin/rfq')}>RFQ Management</div>
                    <div className="section-description">Add, edit and delete RFQ rules</div>
                </div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" />
                    <div className="section-name" onClick={() => history.push('/admin/psm')}>Product Subgroup Management</div>
                    <div className="section-description">Upload Product Subgroup table</div>
                </div> 
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" />
                    <div className="section-name" onClick={() => history.push('/admin/apm')}>Auto Pricing Management</div>
                    <div className="section-description">Upload Quote Rules table</div>
                </div> 
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" />
                    <div className="section-name" onClick={() => history.push('/admin/vsm')}>Shipping Margin Management</div>
                    <div className="section-description">Configure Vendor Shipping Margin</div>
                </div> 
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" aria-hidden="true" />
                    <div className="section-name" onClick={() => history.push('/admin/raven')}>Raven Management</div>
                    <div className="section-description">Refresh Raven Cache</div>
                </div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" aria-hidden="true" />
                    <div className="section-name" onClick={() => history.push('/admin/rsimport')}>RS Import Tool</div>
                    <div className="section-description">RS Import Tool</div>
                </div>
            </div>
        </div>);
    }
}
